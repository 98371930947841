const stripTrailingSlash = url => {
	if (!url || typeof url !== 'string') {
		return url;
	}
	return url.replace(/\/$/, '');
};

// VALIDATION

const APP_CONFIG = window.APP_CONFIG;
if (!APP_CONFIG) {
	throw new Error('APP_CONFIG is null or undefined');
}

// Required Config Props Sanity Check
//['crifApiUrl', 'crifMockUrl', 'contentApiBaseUrl', 'userProfileId', 'portalBaseUrl'].forEach(required => {
//	if (!APP_CONFIG[required]) {
//		throw new Error(`APP_CONFIG missing required property "${required}"`);
//	}
//});

// APP SETTINGS
export const G_SITE_ID = APP_CONFIG.googleAppId;
export const PORTAL_BASE_URL = stripTrailingSlash(APP_CONFIG.portalBaseUrl);
export const MAIN_SITE_ROOT = stripTrailingSlash(APP_CONFIG.mainSiteRoot);
export const CRIF_API_URL = stripTrailingSlash(APP_CONFIG.crifApiUrl);
export const CRIF_MOCK_URL = stripTrailingSlash(APP_CONFIG.crifMockUrl);
export const LOGOUT_API_URL = stripTrailingSlash(APP_CONFIG.logoutApiUrl);
export const CONTENT_API_BASE = stripTrailingSlash(APP_CONFIG.contentApiBaseUrl);
export const USER_PROFILE_ID = APP_CONFIG.userProfileId;
export const AGENCY_IDS = {
	rms: APP_CONFIG.agencyIdRms,
	sw: APP_CONFIG.agencyIdSw,
};
export const AGENCIES_ENDPOINT = stripTrailingSlash(APP_CONFIG.agenciesEndpoint);
export const BASE_API_URL = stripTrailingSlash(APP_CONFIG.baseApiUrl);
export const CASE_TYPES_ENDPOINT = stripTrailingSlash(APP_CONFIG.caseTypes);
export const CUSTOMER_ME_ENDPOINT = stripTrailingSlash(APP_CONFIG.customersMeEndpoint);
export const CUSTOMER_ME_COMPLAINT = stripTrailingSlash(APP_CONFIG.customersMeComplaintEndpoint);
export const CUSTOMER_ME_ENQUIRY = stripTrailingSlash(APP_CONFIG.customersMeEnquiryEndpoint);
export const CUSTOMER_ME_FEEDBACK = stripTrailingSlash(APP_CONFIG.customersMeFeedbackEndpoint);
export const IDENTITY_API_BASE = APP_CONFIG.identityApiBaseUrl;
export const PORTAL_MOCK_URL = stripTrailingSlash(APP_CONFIG.portalMockUrl);
export const USERS_ENDPOINT = stripTrailingSlash(APP_CONFIG.usersEndpoint);
export const USER_TOKEN_VERIFICATION_ENDPOINT = stripTrailingSlash(APP_CONFIG.userTokenVerificationEndpoint);
export const ENABLE_CSP_ONBOARDED_CHECK = APP_CONFIG.enableCSPOnboardCheck === 'true';
export const ONBOARED_CSP_LISTS = APP_CONFIG.gwCSPOnboarded;

//Below are the SI Moved Endpoints
export const COC_AUTHORISED_PERSON_ENDPOINT = APP_CONFIG.cocAuthorisedPersonEndpoint;
export const COC_CUSTOM_ENDPOINT = APP_CONFIG.cocCustomEndpoint;
export const COC_STANDARD_ENDPOINT = APP_CONFIG.cocStandardEndpoint;
export const EVENTS_CATEGORIES_ENDPOINT = APP_CONFIG.eventsCategoriesEndpoint;
export const EVENTS_ENDPOINT = APP_CONFIG.eventsEndpoint;
export const PRODUCTS_ENDPOINT = APP_CONFIG.referenceDataProducts;
export const CLAIMS_SERVICE_PROVIDER = APP_CONFIG.claimsServiceProvider;
export const CLAIMS_SSO_AUTH_URL_TMF = APP_CONFIG.claimsSSOAuthURLTMF;

//Below are the SI Moved URLS
export const ASSET_REGISTER_CAPTURE_URL = APP_CONFIG.assetRegisterCaptureUrl;
export const ASSET_REGISTER_RCA_URL = APP_CONFIG.assetRegisterRCAUrl;
export const CVENT_REGISTRATION_BASE_URL = APP_CONFIG.cventRegistrationBaseUrl;
export const GB_CLAIMS_PORTAL_URL = APP_CONFIG.gbClaimsPortalUrl;

//NewsArticles Base Endpoints
export const NEWS_ARTICLES_ENDPOINT = `${APP_CONFIG.mainSiteRoot}${APP_CONFIG.articleAPIUrl}`;
//Feature flags
export const ASYNC_MODAL_FEATURE = APP_CONFIG.asyncModalFeature === 'true' ? true : false;
export const CHECK_STATUS_INTERVAL = parseInt(APP_CONFIG.checkStatusInterval);
export const TRIGGER_FORGOT_PASSWORD_RECOVERY = APP_CONFIG.triggerForgotPasswordRecovery === 'true' ? true : false;

export const SITECORE_PATHS = {
	accessibilitySettings: APP_CONFIG.pathToAccessibilitySettings,
	accountDetails: APP_CONFIG.pathToAccountDetails,
	contactUs: APP_CONFIG.pathToContactUs,
	homepage: APP_CONFIG.pathToHomepage,
	privacySettings: APP_CONFIG.pathToPrivacySettings,
	pathToSearch: APP_CONFIG.pathToSearch,
	termsConditions: APP_CONFIG.pathToTermsConditions,
};

export const OKTA_CONFIG = {
	url: APP_CONFIG.oktaUrl,
	clientId: APP_CONFIG.oktaClientId,
	issuer: APP_CONFIG.oktaIssuer,
	redirectUri: APP_CONFIG.oktaRedirectUri,
	loggingInUri: APP_CONFIG.oktaLogginInUri,
	environment: APP_CONFIG.oktaEnvironment,
	isIFNSW: true,
	sessionCookies: ['OktaCookieJWT'],
};

export const INACTIVITY_TIMEOUT = parseInt(APP_CONFIG.inactivityTimeout);
export const INACTIVITY_WARNING = parseInt(APP_CONFIG.inactivityWarning);

export const RECAPTCHA_SITE_KEY = APP_CONFIG.recaptchaSiteKey;
export const SHOW_RECAPTCHA = APP_CONFIG.showRecaptcha === 'true';
export const NEW_USER_PROFILE_PAYLOAD = APP_CONFIG.newUserProfilePayload;

export const CRIF_USER_ROLE = APP_CONFIG.oktaCrifUserRole;
export const TMF_USER_ROLE = APP_CONFIG.oktaTMFUserRole;
export const TMF_USER_CLAIMS_ROLE = APP_CONFIG.oktaTMFClaimsUser;

export const OKTA_ROLES = {
	ASSET_REGISTER: APP_CONFIG.oktaAssetRole,
	CAPTURE: APP_CONFIG.oktaCaptureRole,
	CRIF: APP_CONFIG.oktaCrifUserRole,
};

export const SECRET_QUESTION_REQUIRES_PASSWORD =
    'secretQuestionRequiresPassword' in APP_CONFIG ? APP_CONFIG.secretQuestionRequiresPassword : false;

export const ENABLE_REFERENCE_NUMBER = APP_CONFIG.enableReferenceNumber === 'true';

export default null;
