import {OKTA_CONFIG} from '~/config';

export const policyId = ':policyId';
export const agencyId = ':agencyId';
export const typeId = ':typeId';

export const crifPaths = {
	newQuote: `/crif/${typeId}/apply/`,
	amendApplication: `/crif/${typeId}/${policyId}/amend/${agencyId}/`,
	additionalInformation: `/crif/${typeId}/${policyId}/additional-information/${agencyId}/`,
	policyListing: '/crif/policy-listing/',
	details: `/crif/${typeId}/${policyId}/details/${agencyId}/`,
	cancel: `/crif/${typeId}/${policyId}/cancel/${agencyId}/`,
	thankYou: '/crif/thank-you/',
	notification: '/crif/notification/',
	quotePolicyCancelConfirmationPage: `/crif/${typeId}/${policyId}/quote-policy-cancel-confirmation/${agencyId}/`,
};

// Get the relative path from the redirect url
const parser = document.createElement('a');
parser.href = OKTA_CONFIG.redirectUri;

const otherParser = document.createElement('a');
otherParser.href = OKTA_CONFIG.loggingInUri;

export const authenticationPaths = {
	profile: '/profile',
	setRecoveryQuestionPage: '/set-recovery/',
	forgotPassword: '/forgot-password/',
	resetPassword: '/profile/reset-password/',
	expiredPasswordPage: '/profile/reset-expired-password/',
	changeRecoveryQuestionPage: '/change-recovery-question/',
	changePassword: '/change-password/',
	login: '/login/',
	loginCallback: parser.pathname,
	loggingInCallback: otherParser.pathname,
	logout: '/logout/',
};

//These are the components shifted from the SI Project
export const siPaths = {
	authorisedPersonPage: '/authorised-person',
	certificateOfCurrencyPage: '/certificate-of-currency',
	claimsHandover: '/claims/handover',
	claimsApplyForAccess: '/claims/apply-for-claims-access',
};

export const sitePaths = {
	dashboard: process.env.NODE_ENV === 'production' ? '/' : '/dashboard',
	index: process.env.NODE_ENV === 'production' ? '/sitemap' : '/',
	accessibilityPage: '/accessibility-settings/',
	notFoundPage: '/page-not-found/',
	fatalErrorPage: '/fatal-error/',
	expiredPage: '/expired-link',
	unauthorisedPage: '/access-denied',
	cfeFormPage: '/contact-us/',
	cfeGeneralEnquiriesFormPage: '/contact-us/general-enquiries/',
	cfeComplaintsFormPage: '/contact-us/complaints/',
	cfeFeedbackFormPage: '/contact-us/feedback/',
	cfeFormThankYouPage: '/contact-us/thank-you/',
	cfeFormErrorPage: '/contact-us/error/',
	parFormPage: '/portal-access-request/',
	parConfirmationPage: '/portal-access-request/confirmation/',
	userTokenVerificationPage: '/account-verification/',
	eventsListingPage: '/events',
	eventDetailsPage: '/events/event-details',
	newsPage: '/news/',
	catastropheClaimsPage: '/catastrophe-claims/',
};
