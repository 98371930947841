import React from 'react';
import get from 'lodash/get';
import {gaTrackingEvent} from '@icare/core/lib/utils/Utils';
import stores from '~/stores';
import AuthenticatedServices from '~/core/services/AuthenticatedServices';
import {TMF_USER_CLAIMS_ROLE} from '~/config';
import {getUser} from './AuthUtils';

const _projectType = () => {
	const {attributes} = stores.policyApplicationStore.policyData.data;
	return get(attributes, 'projectType', false);
};

const _agencyName = (isDetails = false) => {
	if (isDetails) {
		return stores.policyDetailsStore.policyData.attributes.agencyId;
	} else {
		const {agency, policyData} = stores.policyApplicationStore;
		const {attributes} = policyData.data;
		return get(attributes, 'agencyId', agency.name);
	}
};

export default class AnalyticsHandler extends React.Component {
	// Send uId only once
	static _isUidSent = false;

	static callGaTrackingEvent(analyticsData) {
		gaTrackingEvent(analyticsData);
	}

	// Load GA Tag in DOM
	static initiateLoadOfGATag() {
		if (typeof window.loadGATag === 'function') {
			window.loadGATag();
		}
	}

	//UID logging | unique logging for each visit
	static logUid(uid) {
		if (this._isUidSent) {
			return;
		}
		if (!uid) {
			return;
		}

		// Mark UID as sent
		this._isUidSent = true;
		AnalyticsHandler.callGaTrackingEvent({
			userID: uid,
		});

		// Applied USERID, now load GA tag
		AnalyticsHandler.initiateLoadOfGATag();
	}

	// Submitted when user makes a successful referral form save & review:
	static submitReferral() {
		const {attributes} = stores.policyApplicationStore.policyData.data;
		const analyticsData = {
			event: 'Apply New Construction Risk Policy Referral',
			agency_name_referral: _agencyName(),
			project_name_referral: get(attributes, 'projectName', false),
			project_type_referral: _projectType(),
			referral_required_referral: get(attributes, 'contaminatedLandRegistered', false),
			cover_inception_date_referral: get(attributes, 'riskDates.coverInceptionDate', false),
			construction_start_date_referral: get(attributes, 'riskDates.constructionStartDate', false),
			construction_end_date_referral: get(attributes, 'riskDates.constructionEndDate', false),
			testing_period_referral: get(attributes, 'riskDates.testingPeriod', false),
			post_completion_period_referral: get(attributes, 'riskDates.postCompletionPeriod', false),
			sums_insured_referral: get(attributes, 'products.contractWorks.terms.sumsInsured', false),
			existing_property_referral: get(attributes, 'products.contractWorks.terms.existingProperty', false),
		};
		gaTrackingEvent(analyticsData);
	}

	// Submitted when clicking Bind & Submit button for a quote
	static submitQuote(response) {
		const {attributes} = response.data;
		const analyticsData = {
			event: 'Apply New Construction Risk Policy No Referral',
			agency_name_no_referral: _agencyName(),
			project_name_no_referral: get(attributes, 'projectName', false),
			project_type_no_referral: _projectType(),
			no_referral_required_no_referral: get(attributes, 'contaminatedLandRegistered', false),
			cover_inception_date_no_referral: get(attributes, 'riskDates.coverInceptionDate', false),
			construction_start_date_no_referral: get(attributes, 'riskDates.constructionStartDate', false),
			construction_end_date_no_referral: get(attributes, 'riskDates.constructionEndDate', false),
			testing_period_no_referral: get(attributes, 'riskDates.testingPeriod', false),
			post_completion_period_no_referral: get(attributes, 'riskDates.postCompletionPeriod', false),
			sums_insured_no_referral: get(attributes, 'products.contractWorks.terms.sumsInsured', false),
			existing_property_no_referral: get(attributes, 'products.contractWorks.terms.existingProperty', false),
			contract_works_premium: get(attributes, 'products.contractWorks.values.total', false),
			total_pub_prod_env_premium: get(attributes, 'products.publicImpairmentLiability.values.total', false),
			prof_indemnity_premium: get(attributes, 'products.professionalIndemnity.values.total', false),
			total_additional_value_of_cover: get(attributes, 'products.totalPremiumValue.values.total', false),
			// Additional Info
			wbs_number: get(attributes, 'additionalInfo.wbsNumber', false),
			address_1: !!get(attributes, 'additionalInfo.projectLocation.streetAddress.addressLine1', false),
			address_2: !!get(attributes, 'additionalInfo.projectLocation.streetAddress.addressLine2', false),
			town_city: !!get(attributes, 'additionalInfo.projectLocation.streetAddress.city', false),
			state: !!get(attributes, 'additionalInfo.projectLocation.streetAddress.state', false),
			postcode: !!get(attributes, 'additionalInfo.projectLocation.streetAddress.postCode', false),
			latitude: !!get(attributes, 'additionalInfo.projectLocation.geoLocation.latitude', false),
			longitude: !!get(attributes, 'additionalInfo.projectLocation.geoLocation.longitude', false),
			project_contact_email: !!get(attributes, 'additionalInfo.contactDetails.projectContact.email', false),
		};
		gaTrackingEvent(analyticsData);
	}

	//Submitted when Save & Review is clicked on referral panel
	static referralSave() {
		const analyticsData = {
			event: 'Save Review Referral',
			agency_name_referral: _agencyName(),
			project_type_referral: _projectType(),
		};
		gaTrackingEvent(analyticsData);
	}

	//Submitted when selecting Save & Review on Quote Panel
	static quoteSave() {
		const analyticsData = {
			event: 'Accept Quote No Referral',
			agency_name_no_referral: _agencyName(),
			project_type_no_referral: _projectType(),
		};
		gaTrackingEvent(analyticsData);
	}

	static rejectQuote() {
		const analyticsData = {
			event: 'formsubmitreject',
		};
		gaTrackingEvent(analyticsData);
	}

	//Submitted when a quote document is downloaded
	static quoteDownload() {
		const analyticsData = {
			event: 'QuoteDetailsDownloads',
			select_agency: _agencyName(true),
		};
		gaTrackingEvent(analyticsData);
	}

	//Submitted when a policy document is downloaded
	static policyDownload() {
		const analyticsData = {
			event: 'PolicyDataDownloads',
			select_agency: _agencyName(true),
		};
		gaTrackingEvent(analyticsData);
	}

	//Submitted when documents are uploaded
	static documentsUploaded() {
		const documents = get(stores.supportingDocumentsStore, 'supportingDocuments', null);
		const analyticsData = {
			event: 'Upload',
		};

		for (let i = 0; i < documents.length; i++) {
			// analyticsData[`document${i + 1}`] = _.get(action.documents[i], 'meta.title', '');
			analyticsData[`document${i + 1}_type`] = documents[i].type;
		}
		gaTrackingEvent(analyticsData);
	}

	//Submitted when Step 1 or Step 2 are submitted
	static submittedFields(form) {
		const fields = form.get();
		const items = [];

		for (const field in fields) {
			if (fields[field].value) {
				items.push(fields[field].label);
			}
		}

		// Would be updated.
		const analyticsData = {
			event: 'formfieldscompleted',
			action: location.pathname,
			page_and_form_field_ids: items,
		};
		gaTrackingEvent(analyticsData);
	}

	//Submitted on form validation error
	static error(form) {
		const fieldsAndErrors = form.errors();
		const items = [];

		for (const field in fieldsAndErrors) {
			if (fieldsAndErrors[field]) {
				items.push(`${field}:${fieldsAndErrors[field]}`);
			}
		}

		const analyticsData = {
			event: 'errorsummary',
			action: location.pathname,
			page_and_form_errors: items,
		};
		gaTrackingEvent(analyticsData);
	}

	static trackCfeSubmit() {
		const {formData, requestType} = stores.cfeFormStore;
		const {cfeGeneral, cfeComplaint, cfeFeedback, cfeGlobal} = formData;

		const analyticsData = {};

		switch (requestType) {
			case 'Enquiry':
				analyticsData.event = 'D3 General Enquiries';
				analyticsData.d3_gen_enq_relates_to = cfeGeneral.enquiryType;
				analyticsData.d3_gen_enq_contact_me_back = cfeGlobal.contactMeBack;
				if (cfeGlobal.contactMeBack === 'yes') {
					analyticsData.d3_gen_enq_contact_me_by = cfeGlobal.contactMethod;

					if (cfeGlobal.phoneType) {
						analyticsData.d3_gen_enq_contact_phonetype = cfeGlobal.phoneType;
					}
				}
				break;
			case 'Complaint':
				analyticsData.event = 'D3 Submitted Complaints';
				analyticsData.d3_sub_comp_relates_to = cfeComplaint.complaintType;
				analyticsData.d3_sub_comp_contact_me_back = cfeGlobal.contactMeBack;
				if (cfeGlobal.contactMeBack === 'yes') {
					analyticsData.d3_sub_comp_contact_me_by = cfeGlobal.contactMethod;

					if (cfeGlobal.phoneType) {
						analyticsData.d3_sub_comp_contact_phonetype = cfeGlobal.phoneType;
					}
				}
				break;
			case 'Feedback':
				analyticsData.event = 'D3 Submitted Feedback';
				analyticsData.d3_sub_feedback_relates_to = cfeFeedback.feedbackType;
				analyticsData.d3_sub_feedback_contact_me_back = cfeGlobal.contactMeBack;
				if (cfeGlobal.contactMeBack === 'yes') {
					analyticsData.d3_sub_feedback_contact_me_by = cfeGlobal.contactMethod;

					if (cfeGlobal.phoneType) {
						analyticsData.d3_sub_feedback_contact_phonetype = cfeGlobal.phoneType;
					}
				}
				break;
			default:
		}
		gaTrackingEvent(analyticsData);
	}

	static trackParFormSubmission() {
		const {parFormPage} = stores.parFormStore.formData;
		const analyticsData = {
			event: 'Portal Access Request',
			par_organisation: parFormPage.agency.label,
			par_pos_title: parFormPage.positionTitle,
			par_phone: parFormPage.phone !== '',
			par_mobile: parFormPage.mobile !== '',
		};
		gaTrackingEvent(analyticsData);
	}

	static trackParFormErrors(values) {
		const analyticsData = {
			event: 'Portal Access Request Submission Error',
			err_fname: values.parFormPage__firstName !== '',
			err_lname: values.parFormPage__lastName !== '',
			err_organisation: values.parFormPage__agency !== '',
			err_pos_title: values.parFormPage__positionTitle !== '',
			err_email_add: values.parFormPage__workEmailAddress !== '',
			err_phone: values.parFormPage__phone !== '',
			err_mobile: values.parFormPage__mobile !== '',
		};
		gaTrackingEvent(analyticsData);
	}

	// TRACKING EVENT: Login Error Messages
	static loginError(userName, oktaErrorMessage) {
		gaTrackingEvent({
			event: 'Login Error Messages',
			loginerrormessage: oktaErrorMessage,
		});
	}

	// TRACKING EVENT: login_success
	static loginSuccess() {
		getUser().then(
			u => {
				if (!u) {
					return;
				}
				gaTrackingEvent({
					event: 'login_success',
					uid: u.user.crmContactId,
				});
			},
			() => null,
		);
	}

	static getScheme(roles) {
		let hasClaimsAccess = false;
		if (roles && roles.length > 0) {
			hasClaimsAccess = roles.find(userRole => {
				return userRole === TMF_USER_CLAIMS_ROLE;
			});
		}
		return hasClaimsAccess ? 'tmf' : 'ifnsw';
	}

	// TRACKING EVENT: login_success
	static ifnswLoginSuccess(user) {
		AuthenticatedServices.getClientDetails().then(value => {
			if (value.body) {
				const clientDetails = value.body.attributes;
				const orgainizationDetails = clientDetails.organisation;
				const userData = {
					agencyId: user.siAgencyId,
					agencyname: orgainizationDetails.agency.replace(/[^\w\s]/gi, ''),
					loggedin_usertype: this.getScheme(user.groups) ? 'TMF Agency user' : 'IfNSW user',
					scheme: this.getScheme(user.groups),
				};
				if(localStorage){
					localStorage.setItem('analyticData',JSON.stringify(userData));
				}

				gaTrackingEvent({
					event: 'ifnsw_login_success',
					userID: user.siContactId,
					...userData,
				});
			}
		});
	}
}
